import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import styled from "styled-components";
import "../main.css";

const Main = styled.main`
  min-height: 90vh;
  max-width: 800px;
  margin: auto;
  padding: 20px;
`;

const Layout = ({ children, title }) => (
  <>
    <Header title={title} />
    <Main>{children}</Main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
};

export default Layout;
