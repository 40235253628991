import { Link } from "gatsby";
import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";

import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHamburger,
  faPhone,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

const HeaderContainer = styled.header`
    background-color: #2cabbd;

    background-size: 120px;
    color: white;
   
    .titleBar {
        height: 220px;
        background-color: black;
        border-bottom: 5px solid #2593a1;

        h1 {
            position: relative;
            top: -140px;
            z-index: 2;
            text-align: center;
            font-family: cursive;
            font-weight: 900;
            font-size: 50px;
            line-height: 100%;
            text-shadow: 0px 0px 30px black,0px 0px 30px black,0px 0px 30px black,0px 0px 10px black,0px 0px 10px black;
        }
    }

.headerLogoHolder {
    display: block;
    z-index: 4;
    position: absolute;
    height: 110px;
    background: none;
    top: 1px;
}

.headerLogo {
    height: 100%;

    margin: auto;
 
}

.headerBGImage
{
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
    opacity: .6;
}
}

.mobileNav {

    display:flex;
    flex-direction: column;

    > a {
        color: white;
        text-decoration: none;
        padding: 5px;
        flex: 1;
        height: 30px;
        line-height:30px;
    }
}



.slide {
  clear:both;
  background-color: #2cabbd;
  background-size: 120px;
  
      width:100%;
  height:0px;
  overflow: hidden;
  text-align: center;
  transition: height .4s ease;
  z-index:3;
  left: 0px;
  top: 80px;
  position: absolute;

    > div {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        align-items: flex-start;
        justify-content: space-evenly;
        align-content: center;
        height: 400px;
    }

  a {
      padding: 10px 40px;
      height: 30px;
      color: white;
      text-decoration: none;
      border-radius: 5px;
      background-color: rgba(0,0,0,.8);
  }
}


#touch {position: absolute; opacity: 0; height: 0px;}    

#touch:checked + .slide {height: 400px;} 



nav {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    grid-gap: 10px;
    margin: auto;
    width: 95%;
    max-width: 800px;

    > * {
        color: white;
        text-decoration: none;
        padding: 5px;
        flex: 1;
        height: 30px;
        line-height:30px;
    }

    .icon.right {
        text-align: right;
    }

    .desktop {
        display: none;
    }

    @media (min-width: 800px) {
        .mobile {
            display: none;
        }
   
        .desktop {
        display: block;
        }

  
    > a {
        background-color: #2593a1;
        text-align: center;
    }

    > a[aria-current=page]
    {
        background: #15525a;
    }


}

`;

function Header({ title }) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allSanityHomepage {
            nodes {
              name
              logo {
                ...ImageWithPreview
              }
            }
          }

          bgImage: file(relativePath: { eq: "gplay.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          allSanityPhoto {
            nodes {
              image {
                ...ImageWithPreview
              }
              id
              description
            }
          }
        }
      `}
      render={(data) => (
        <HeaderContainer
          style={{
            backgroundImage: `url(${data.bgImage.childImageSharp.fluid.src})`,
          }}
        >
          <nav>
            <div className={`mobile icon`}>
              <label onClick={toggleMenu} for="touch">
                {isMenuOpen && (
                  <FontAwesomeIcon className="bars" icon={faBars} size="1x" />
                )}
                {!isMenuOpen && (
                  <FontAwesomeIcon
                    className="bars"
                    icon={faWindowClose}
                    size="2x"
                  />
                )}
              </label>
              <input type="checkbox" id="touch" />
              <div className={`slide`}>
                <div>
                  <Link to="/menu">Menu</Link>
                  <Link to="/catering">Catering</Link>
                  <Link to="/drinks">Drinks</Link>
                  <Link to="/events">Live Music</Link>
                  <Link to="/aboutus">About Us</Link>
                  <Link to="/specials">Specials</Link>
                </div>
              </div>
            </div>

            <Link className="desktop" to="/menu">
              Menu
            </Link>

            <Link className="desktop" to="/catering">
              Catering
            </Link>
            <Link className="desktop" to="/drinks">
              Drinks
            </Link>

            <div className="headerLogoHolder">
              <Link to="/">
                <Image
                  className="headerLogo"
                  {...data.allSanityHomepage.nodes[0].logo}
                  height={70}
                  alt="Cartel Taco Bar Logo"
                />
              </Link>
            </div>
            <div className={`desktop icon`}></div>

            <Link className="desktop" to="/events">
              Live Music
            </Link>
            <Link className="desktop" to="/aboutus">
              About Us
            </Link>

            <Link className="desktop" to="/specials">
              Specials
            </Link>
            <a href={`tel:+18172006364`} className={`mobile icon right`}>
              <FontAwesomeIcon icon={faPhone} size="2x" />
            </a>
          </nav>

          <div className="titleBar">
            <Image
              className="headerBGImage"
              {...data.allSanityPhoto.nodes[0].image}
              height={70}
              alt="Cartel Taco Bar Logo"
            />
            <h1>{title}</h1>
          </div>
        </HeaderContainer>
      )}
    />
  );
}

export default Header;
