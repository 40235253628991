import { Link } from "gatsby";
import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Container, Row, Col } from "react-grid-system";

const Footer = styled.footer`
  background-color: #2cabbd;

  background-size: 120px;
  color: white;

  .footerLogo {
    height: 50px;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    word-break: keep-all;
  }

  .footerLogoHolder {
    display: none;
    @media only screen and (min-width: 600px) {
      display: block;
    }
  }

  .footerBottomBar {
    background-color: #145d70;
    position: relative;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    a {
      color: white;
    }
  }

  .footerGrid {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    width: 95%;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 10px 0px;

    > * {
      flex: 1;
      text-align: center;
    }

    .socialLinks {
      display: flex;
      flex-direction: row;
      grid-gap: 10px;
      justify-content: space-around;
    }
  }
`;

function footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          allSanityHomepage {
            nodes {
              name
              logo {
                ...ImageWithPreview
              }
            }
          }

          bgImage: file(relativePath: { eq: "gplay.png" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }

          allSanityPhoto {
            nodes {
              image {
                ...ImageWithPreview
              }
              id
              description
            }
          }
        }
      `}
      render={(data) => (
        <Footer
          style={{
            backgroundImage: `url(${data.bgImage.childImageSharp.fluid.src})`,
          }}
        >
          <Container className="footerGrid">
            <Row align="center" justify="center" style={{ width: "100%" }}>
              <Col>
                <Link to="/contact">Contact Us</Link>
              </Col>
              <Col className="footerLogoHolder">
                <Link to="/">
                  <Image
                    className="footerLogo"
                    {...data.allSanityHomepage.nodes[0].logo}
                    height={70}
                    alt="Cartel Taco Bar Logo"
                  />
                </Link>
              </Col>
              <Col className="socialLinks" height={200}>
                <div>
                  <a
                    key="facebook"
                    rel="noopener noreferrer"
                    aria-label="Facebook"
                    target="_blank"
                    href={`//facebook.com/carteltacobar`}
                  >
                    <FontAwesomeIcon
                      className="bars"
                      icon={faFacebook}
                      size="1x"
                    />
                  </a>
                </div>
                <div>
                  <a
                    key="twitter"
                    rel="noopener noreferrer"
                    aria-label="Twitter"
                    target="_blank"
                    href={`//twitter.com/carteltacobar`}
                  >
                    <FontAwesomeIcon
                      className="bars"
                      icon={faTwitter}
                      size="1x"
                    />
                  </a>
                </div>
                <div>
                  <a
                    key="Instagram"
                    rel="noopener noreferrer"
                    aria-label="Instagram"
                    target="_blank"
                    href={`//instagram.com/carteltacobar`}
                  >
                    <FontAwesomeIcon icon={faInstagram} size="1x" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="footerBottomBar">
            Site By: Digital Restaurant -{" "}
            <a
              key="digirest.com"
              rel="noopener noreferrer"
              aria-label="Digital Restaurant"
              target="_blank"
              href={`//digirest.com`}
            >
              digirest.com
            </a>
          </div>
        </Footer>
      )}
    />
  );
}

export default footer;
